<template>
  <v-layout h="100vh">
    <v-container flex="~ justify-center col items-center" m="a">
      <div class="w-md-50 w-100 text-center">
        <h1 class="text-h4 text-md-h2 font-weight-bold my-6">
          cloutware
        </h1>

        <div class="text-body-1 text-medium-emphasis mb-10">
          Social Media reposting tool
        </div>

        <div class="d-flex ga-4 justify-center">
          <VBtn text="Login with Google" prepend-icon="material-symbols:login-rounded" @click="loginWithGoogle" />
        </div>
      </div>

      <div class="v-bg position-absolute bottom-0 left-0 right-0 top-0">
        <div aria-hidden="true" class="h-100 w-100 overflow-hidden opacity-20" />
      </div>
    </v-container>
  </v-layout>
</template>

<style scoped>
  .v-bg {
    filter: blur(56px);
    pointer-events: none;
  }

  .v-bg > div {
    background: linear-gradient(
      to bottom right,
      rgb(var(--v-theme-primary)),
      rgb(var(--v-theme-error))
    );
    z-index: -10;
    clip-path: polygon(20% 50%, 27% 66%, 41% 66%, 50% 50%, 41% 34%, 27% 34%, 20% 50%, 55% 50%, 62% 66%, 76% 66%, 85% 50%, 76% 34%, 62% 34%, 55% 50%, 30% 50%, 37% 66%, 51% 66%, 60% 50%, 51% 34%, 37% 34%, 30% 50%);
  }
</style>
